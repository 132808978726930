import React, { Component } from 'react';
import Breadcrumb from '../../reusable/Breadcrumb';
import Image from '../../../assets/img/200L@2x.png';
import axios from 'axios';

export default class ProductDetail extends Component {

    constructor( props ) {
        super( props );

        this.state = {
            product: {},
            breadcrumbArray: []
        };

        this.getProduct = this.getProduct.bind( this );
        
        this.getProduct();
    }

    getProduct() {

        axios.get(`https://permatrol-backend.herokuapp.com/product/${ this.props.match.params.id }`)
            .then( response => {
                this.setState({
                    product: { ...response.data },
                    breadcrumbArray: [
                        { title: 'Inicio', path:"/" }, 
                        { title: response.data.category_name, path:"/" }, 
                        { title: response.data.name, path:"/" },
                    ]
                });
            });
    }

    render() {
        return (
            <div>
                <Breadcrumb segments={ this.state.breadcrumbArray } ></Breadcrumb>
                <section className="product-detail">
                        <div className="product-info">
                            <div className="product-info__img">
                                <img src={ 'https://permatrol-backend.herokuapp.com/img/' + this.state.product.image } alt="Product"/>
                            </div>
                            <div className="product-info__info">
                                <h6>{ this.state.product.name }</h6>
                                {/* <span>sku-pe 12312</span> */}
                                <p className="description">{ this.state.product.description || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque. Phasellus arcu tortor, malesuada feugiat tincidunt eu, ornare vitae sem. Donec convallis nisl lacinia turpis dignissim, id tristique turpis tincidunt.' }</p>
                                <p className="presentations">Presentaciones</p>
                                <div className="presentations-container">
                                    { this.state.product && this.state.product.characteristics &&
                                        this.state.product.characteristics.map( ( char, i ) => <div key={ i } className="presentations-container__item">{ char }</div>) }
                                </div>
                                { this.state.product.pdf &&
                                    <a target="_blank" href={ `https://permatrol-backend.herokuapp.com/datasheet?file=${this.state.product.pdf}` } >Descargar ficha técnica</a> }
                            </div>
                        </div>
                        <div className="products-sidebar">
                            <p className="products-sidebar__title">Presentación de 200 L</p>
                            <div className="products-sidebar__product">
                                <img src={ Image } alt="Presentación de 200 L"/>
                            </div>
                            {
                                /* [1, 2, 3, 4].map( ( i ) => (
                                    <div className="products-sidebar__product" key={ i }>
                                        <img src={ Image } alt="kjhk"/>
                                        <p>Permagel 1/2 l</p>
                                    </div>
                                )) */
                            }
                        </div>
                        <div className="characteristics">
                            <div className="characteristics__item">
                                <h6>Descripción</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque. Phasellus arcu tortor, malesuada feugiat tincidunt eu, ornare vitae sem. Donec convallis nisl lacinia turpis dignissim, id tristique turpis tincidunt. Nullam gravida lorem diam, lacinia venenatis felis semper quis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec non enim imperdiet, mattis elit at, pretium mauris nullam.</p>
                            </div>
                            <div className="characteristics__item">
                                <h6>Ventajas</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque. Phasellus arcu tortor, malesuada feugiat tincidunt eu, ornare vitae sem. Donec convallis nisl lacinia turpis dignissim, id tristique turpis tincidunt. Nullam gravida lorem diam, lacinia venenatis felis semper quis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec non enim imperdiet, mattis elit at, pretium mauris nullam.</p>
                            </div>
                            <div className="characteristics__item">
                                <h6>Recomendaciones</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque. Phasellus arcu tortor, malesuada feugiat tincidunt eu, ornare vitae sem. Donec convallis nisl lacinia turpis dignissim, id tristique turpis tincidunt. Nullam gravida lorem diam, lacinia venenatis felis semper quis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec non enim imperdiet, mattis elit at, pretium mauris nullam.</p>
                            </div>
                        </div>
                    {/* <div className="product-detail__left">
                        <div className="product-info">
                            <div className="product-info__img">
                                <img src={ 'https://permatrol-backend.herokuapp.com/img/' + this.state.product.image } alt="Product"/>
                            </div>
                            <div className="product-info__info">
                                <h6>{ this.state.product.name }</h6>
                                <span>sku-pe 12312</span>
                                <p className="description">{ this.state.product.description }</p>
                                <p className="presentations">Presentaciones</p>
                                <div className="presentations-container">
                                    { this.state.product && this.state.product.characteristics &&
                                        this.state.product.characteristics.map( ( char, i ) => <div key={ i } className="presentations-container__item">{ char }</div>) }
                                </div>
                                <button>Descargar ficha técnica</button>
                            </div>
                        </div>
                        <div className="product-characteristic">
                            <h6>Descripción</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque. Phasellus arcu tortor, malesuada feugiat tincidunt eu, ornare vitae sem. Donec convallis nisl lacinia turpis dignissim, id tristique turpis tincidunt. Nullam gravida lorem diam, lacinia venenatis felis semper quis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec non enim imperdiet, mattis elit at, pretium mauris nullam.</p>
                        </div>
                        <div className="product-characteristic">
                            <h6>Ventajas</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque. Phasellus arcu tortor, malesuada feugiat tincidunt eu, ornare vitae sem. Donec convallis nisl lacinia turpis dignissim, id tristique turpis tincidunt. Nullam gravida lorem diam, lacinia venenatis felis semper quis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec non enim imperdiet, mattis elit at, pretium mauris nullam.</p>
                        </div>
                        <div className="product-characteristic">
                            <h6>Recomendaciones</h6>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque. Phasellus arcu tortor, malesuada feugiat tincidunt eu, ornare vitae sem. Donec convallis nisl lacinia turpis dignissim, id tristique turpis tincidunt. Nullam gravida lorem diam, lacinia venenatis felis semper quis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec non enim imperdiet, mattis elit at, pretium mauris nullam.</p>
                        </div>
                    </div> */}
                    {/* <div className="product-detail__right">
                        <div className="products-sidebar">
                            <p className="products-sidebar__title">Otras presentaciones</p>
                            {
                                [1, 2, 3, 4].map( ( i ) => (
                                    <div className="products-sidebar__product" key={ i }>
                                        <img src={ Image } alt="kjhk"/>
                                        <p>Permagel 1/2 l</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div> */}
                </section>
            </div>
        );
    }

}