import React, { Component } from 'react';
import 'react-input-range/lib/css/index.css';
import axios from 'axios';
import InputRange from 'react-input-range';

export default class Filters extends Component {

    constructor( props ) {
        super();
        
        this.state = {
            value: { min: 0, max: 500 },
            categories: [],
            products: [],
            selectedCategories: []
        };

        this.onInputMinChange = this.onInputMinChange.bind( this );
        this.onInputMaxChange = this.onInputMaxChange.bind( this );
        this.getCategories = this.getCategories.bind( this );

        this.getCategories();
    }

    getCategories() {

        axios.get('https://permatrol-backend.herokuapp.com/categories')
            .then( response => this.setState({ categories: [ ...response.data.elements ] }) );
    }

    onInputMinChange( e ) {

        const min = e.target.value ? Number(e.target.value) : 0;
        this.setState({
            value: { min, max: this.state.value.max }
        });
    }

    onInputMaxChange( e ) {

        const min = e.target.value ? Number(e.target.value) : 0;
        this.setState({
            value: { min, max: this.state.value.max }
        });
    }

    render() {
        const {
                characteristics,
                selectedCategory,
                selectedBrand,
                selectedCharacteristic,
                categoryChangeHandler,
                brandChangeHandler,
                characteristicChangeHandler,
                brands } = this.props;

        return (
            <div className="filters">
                <div className="filters__header">
                    Comprar por
                </div>

                <div className="filters__price-range">
                    <p>Tamaño</p>
                    <InputRange
                        maxValue={ 500 }
                        minValue={ 0 }
                        value={ this.state.value }
                        onChange={ value => this.setState({ value }) } />
                    <div className="input-container">
                        <label htmlFor="min">$</label>
                        <input
                            type="text"
                            name="min"
                            className="input"
                            onChange={ this.onInputMinChange }
                            value={ this.state.value.min }/>

                        <span>-</span>
                        
                        <label htmlFor="max">$</label>
                        <input
                            type="text"
                            name="max"
                            className="input"
                            onChange={ this.onInputMaxChange }
                            value={ this.state.value.max }/>
                    </div>
                </div>
            
                <div className="filters__categories">
                    <p>Categoría</p>
                    <ul className="categories-list">
                        { this.state.categories
                            .map( ( cat, i ) =>
                                    ( <li
                                        key={ i }
                                        onClick={ () => categoryChangeHandler( cat ) }
                                        className={ `categories-list__item ${ selectedCategory === cat.id ? 'categories-list__item--active' : '' }` }>
                                            { `${ cat.name } (${ cat.number_of_coincidences })` }
                                        </li> ) ) }
                        <li
                            className="categories-list__item"
                            onClick={ () => categoryChangeHandler() }>Limpiar filtro</li>
                    </ul>
                </div>

                <div className="filters__brand-2">
                    <p>Marca</p>
                    <div className="items-container">
                        { brands.map( ( brand, i ) =>
                            <p
                                key={ i }
                                onClick={ () => brandChangeHandler( brand.id ) }
                                className={ `items-container__item ${ selectedBrand === brand.id ? 'items-container__item--active' : '' }` }>{ brand.name }</p> ) }
                        <p
                            onClick={ () => brandChangeHandler() }
                            className="items-container__item">Limpiar filtro</p>
                    </div>
                </div>
                
                <div className="filters__presentation">
                    <p>Presentación</p>
                    <div className="items-container">
                        { characteristics.map( ( char, i ) =>
                            <p
                                key={ i }
                                onClick={ () => characteristicChangeHandler( char.name ) }
                                className={ `items-container__item ${ selectedCharacteristic === char.name ? 'items-container__item--active' : '' }` }>{ char.name }</p>) }
                        <p
                            onClick={ () => characteristicChangeHandler() }
                            className="items-container__item">Limpiar filtro</p>
                    </div>
                </div>
            </div>
        );
    }

}