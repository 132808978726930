import React, { Component } from 'react';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as Logo } from '../../assets/img/logo_blanco.svg';
import { ReactComponent as ShieldIcon } from '../../assets/icons/shield.svg';
import { ReactComponent as QualityIcon } from '../../assets/icons/quality.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as PlaceholderIcon } from '../../assets/icons/placeholder.svg';
import { ReactComponent as Phone } from '../../assets/icons/ft-phone.svg';
import { ReactComponent as Email } from '../../assets/icons/ft-email.svg';
import { ReactComponent as Clock } from '../../assets/icons/ft-clock.svg';
import { ReactComponent as Marker } from '../../assets/icons/marker.svg';
import axios from 'axios';

export default class Footer extends Component {

    constructor() {
        super();

        this.state = {
            emailSent: false
        }

        this.sendEmail = this.sendEmail.bind( this );
    }

    sendEmail() {

        axios.post('https://permatrol-backend.herokuapp.com/email', { email: this.refs.emailInput.value })
            .then( response => {
                this.refs.emailInput.value = '';
                this.setState({ emailSent: true }, () => {

                    setTimeout(() => this.setState({ emailSent: false }), 4000);
                });

            });
    }

    render() {
        return (
            <footer className="footer">
                <div className="icons-bar">
                    <div className="icons-bar__item">
                        <div className="ib-circle">
                            <ShieldIcon />
                        </div>
                        <div className="info">
                            <p>Lorem ipsum</p>
                            <p>lorem ipsumer dolon</p>
                        </div>
                    </div>
                    <div className="icons-bar__item">
                        <div className="ib-circle">
                            <QualityIcon />
                        </div>
                        <div className="info">
                            <p>Lorem ipsum</p>
                            <p>lorem ipsumer dolon</p>
                        </div>
                    </div>
                    <div className="icons-bar__item">
                        <div className="ib-circle">
                            <ClockIcon />
                        </div>
                        <div className="info">
                            <p>Lorem ipsum</p>
                            <p>lorem ipsumer dolon</p>
                        </div>
                    </div>
                    <div className="icons-bar__item">
                        <div className="ib-circle">
                            <PlaceholderIcon />
                        </div>
                        <div className="info">
                            <p>Lorem ipsum</p>
                            <p>lorem ipsumer dolon</p>
                        </div>
                    </div>
                </div>
                <div className="information">
                    <div className="information__section information__section--contact">
                        <h4 className="title">Contacto</h4>
                        <ul className="list">
                            <li className="mb-10px">
                                <Phone className="icon"></Phone>
                                <p>Teléfono: 37 47 44 38 45 {/* 36 89 35 15 / 36 89 13 09 */}</p>
                            </li>
                            <li className="mb-10px">
                                <Email className="icon"></Email>
                                <p>lubricantespermatrol@hotmail.com</p>
                            </li>
                            <li className="mb-10px">
                                <Clock className="icon"></Clock>
                                <p>Lunes a Viernes <br/>
                                    9:00 a.m. - 2:00 p.m. / 3:00 p.m. - 6:30 p.m</p>
                            </li>
                        </ul>
                    </div>
                    <div className="information__section information__section--us">
                        <h4 className="title">Nosotros</h4>
                        <ul className="list">
                            <li className="mb-8px">
                                <Marker className="icon"></Marker>
                                <p><strong>Lubricantes el Campeón (Suc. Calzada)</strong> <br/>
                                    Domicilio. Calzada Flavio Romero de Velasco #40 B <br/>
                                    Col. Santuario <br/>
                                    Cp. 46600 <br/>
                                    Ameca, Jal. <br/>
                                    Tel. 3757584991
                                </p>
                            </li>
                            <li className="mb-8px">
                                <Marker className="icon"></Marker>
                                <p>
                                    <strong>Distribuidora Permatrol (Matriz)</strong> <br/>
                                    Domicilio. Dr. Luis Romero Arias #34 B <br/>
                                    Col. Doctores <br/>
                                    Cp. 46600 <br/>
                                    Ameca, Jal <br/>
                                    Tel . 3757582633 / 3757583173
                                </p>
                            </li>
                            <li className="mb-8px">
                                <Marker className="icon"></Marker>
                                <p>
                                    <strong>Lubricantes del Campeón (Suc. Patria)</strong> <br/>
                                    Domicilio. Av. Patria Oriente #28 A <br/>
                                    Col. Santa Cecilia <br/>
                                    Cp. 46610 <br/>
                                    Ameca, Jal <br/>
                                    Tel. 3757587876
                                </p>
                            </li>
                            <li className="mb-8px">
                                <Marker className="icon"></Marker>
                                <p>
                                    <strong>Pronaca (Suc. Sauces)</strong> <br/>
                                    Domicilio. Jacarandas #290 Sur <br/>
                                    Col. Los Sauces <br/>
                                    Cp. 63197 <br/>
                                    Tepic, Nay <br/>
                                    Tel. 3112145042
                                </p>
                            </li>
                            <li className="mb-8px">
                                <Marker className="icon"></Marker>
                                <p>
                                    <strong>Pronaca (Suc. Nayarabastos)</strong> <br/>
                                    Domicilio. Av. Tecnologico #3630 <br/>
                                    Col. Miguel Hidalgo <br/>
                                    Cp. 63193 <br/>
                                    Tepic, Nay <br/>
                                    Tel. 3112196605
                                </p>
                            </li>
                            <li className="mb-8px">
                                <Marker className="icon"></Marker>
                                <p>
                                    <strong>Pronaca (Suc Mololoa)</strong> <br/>
                                    Domicilio. Av. México #778 B <br/>
                                    Col doctores <br/>
                                    Cp 630330 <br/>
                                    Ameca, Jal <br/>
                                    Tel. 3112176605
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="email-banner">
                    <div className="email-banner__logo">
                        <Logo></Logo>
                    </div>
                    <div className="email-banner__email">
                        { this.state.emailSent && <span>Hemos recibido tu correo, en breve nos pondremos en contacto contigo!</span> }
                        { !this.state.emailSent && <p>Boletín</p> }
                        { !this.state.emailSent && <div className="input-container">
                            <input
                                type="text"
                                ref="emailInput"
                                placeholder="Escribe tu correo aquí"/>
                                <button onClick={ this.sendEmail }>enviar</button>
                            </div>}
                    </div>
                </div>
                <div className="reserved-rights">
                    <p>© 2019, Permatrol. &nbsp;&nbsp;Todos los derechos reservados. &nbsp;&nbsp;Por <strong>CACUMEN</strong></p>
                </div>
            </footer>
        );
    }

}