import React, { Component } from 'react';
import Promo from './Promo';
import Breadcrumb from '../../reusable/Breadcrumb';

export default class Promos extends Component {

    constructor() {
        super();

        this.breadcrumbArray = [
            { title: 'Inicio', path:"/" },
            { title: 'Promos', path:"/promos" }
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb segments={ this.breadcrumbArray }></Breadcrumb>
                <section className="promos">
                    <Promo></Promo>
                </section>
            </div>
        );
    }

}