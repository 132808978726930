import React, { Component } from 'react';
import Slideshow from './Slideshow';
import FindYourProductBanner from './FindYourProductBanner';
import FeaturedProducts from './FeaturedProducts';

export default class Welcome extends Component {

    constructor( props ) {
        super();
    }

    gotoProductDetail() {

        this.props.history.push('/product-detail/1');
    }

    render() {
        return (
            <section className="welcome">
                <Slideshow></Slideshow>
                <FindYourProductBanner></FindYourProductBanner>
                <FeaturedProducts></FeaturedProducts>
            </section>
        );
    }

}