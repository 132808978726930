import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class Breadcrumb extends Component {

    static get propTypes() {
        return {
            segments: PropTypes.arrayOf( PropTypes.object )
        }
    }

    constructor( props ) {
        super();

        this.displayBreadcrumbItems = this.displayBreadcrumbItems.bind( this );
    }

    displayBreadcrumbItems() {

        const { segments } = this.props; 
        const items = [];

        segments.forEach(( segment, i ) => {
            
            items.push(<Link to={ segment.path } className="breadcrumb__segment" key={ `seg_${i}` }>{ segment.title }</Link>);
            
            if ( i !== segments.length - 1 ) {

                items.push(<span className="breadcrumb__separator" key={ `sep_${i}` }>></span>);
            }
        });

        return items;
    }

    render() {
        return (
            <nav className="breadcrumb">
                { this.displayBreadcrumbItems() }
            </nav>
        );
    }

}