import React, { Component } from 'react';
import Breadcrumb from '../../reusable/Breadcrumb';
import Image from '../../../assets/img/contact.png';
import axios from 'axios';

export default class Contact extends Component {

    constructor() {
        super();

        this.breadcrumbArray = [
            { title: 'Inicio', path:"/" }, 
            { title: 'Contacto', path:"/contact" }
        ];

        this.emailRegex = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]{2,64}@[(\w+\-+)|(\w+)]{1,63}\.[\w]{2,6}(\.[\w]{2,6})*$/i;
        this.state = {
            emailSent: false,
            nameError: true,
            emailError: false,
            nameError: false
        }

        this.sendEmail = this.sendEmail.bind( this );
        this.isFormValid = this.isFormValid.bind( this );
    }

    isFormValid() {

        this.setState({ nameError: false, emailError: false });

        let nameError = false;
        let emailError = false;

        if ( !this.refs.nameInput.value ) {

            nameError = true;
        }
        if ( !this.refs.emailInput.value || !this.emailRegex.test( this.refs.emailInput.value ) ) {

            emailError = true;
        }

        this.setState({ nameError, emailError });
        
        return !nameError && !emailError;
    }

    sendEmail() {

        if ( !this.isFormValid() ) { return; }
        
        axios.post('https://permatrol-backend.herokuapp.com/contact-email',
            {
                email: this.refs.emailInput.value,
                name: this.refs.nameInput.value,
                phone: this.refs.phoneInput.value,
                comments: this.refs.commentsInput.value
            }).then( response => {
                this.refs.emailInput.value = '';
                this.refs.nameInput.value = '';
                this.refs.phoneInput.value = '';
                this.refs.commentsInput.value = '';
                this.setState({ emailSent: true }, () => {

                    setTimeout(() => this.setState({ emailSent: false }), 4000);
                });

            });
    }

    render() {
        return(
            <div>
                <Breadcrumb segments={ this.breadcrumbArray }></Breadcrumb>
                <section className="contact">
                    <img src={ Image } alt="Contact" className="contact__image"/>
                    <form className="contact__form">
                        <h6>Contáctanos</h6>
                        <p>Si necesitas ayuda o quieres saber mas acerca de nuestros productos no dudes en contactarnos.</p>
                        <div className="fields">
                            <input
                                ref="nameInput"
                                type="text"
                                className={ `input input-name ${ this.state.nameError ? 'input-error' : '' }` }
                                onKeyDown={ () => this.setState({ nameError: false }) }
                                placeholder="Nombre*"/>
                            <input
                                ref="emailInput"
                                type="text"
                                className={ `input input-email ${ this.state.emailError ? 'input-error' : '' }` }
                                onKeyDown={ () => this.setState({ emailError: false }) }
                                placeholder="Correo electrónico*"/>
                            <input
                                ref="phoneInput"
                                type="text"
                                className={ `input input-tel` }
                                placeholder="Núm. de Telefono / Celular"/>
                            <textarea
                                ref="commentsInput"
                                className={ `input textarea-comments` }
                                placeholder="Comentarios"></textarea>
                        </div>
                        <button onClick={ this.sendEmail } type="button">Enviar</button>
                        { this.state.emailSent && <p>Hemos recibido tu correo, en breve nos pondremos en contacto contigo!</p> }
                    </form>
                </section>
            </div>
        );
    }

}