import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/icons/mm-arrow.svg';

class MM extends Component {

    constructor( props ) {
        super();

        this.state = {
            showSubmenu: false
        }

        this.menuContainerClickHandler = this.menuContainerClickHandler.bind( this );
        this.allProductsClickHandler = this.allProductsClickHandler.bind( this );
        this.categoresClickHandler = this.categoresClickHandler.bind( this );
    }

    menuContainerClickHandler( e ) {

        if ( e.target.className === 'mobile-menu-container' ) {

            this.props.closeMenu();
        }
    }

    menuItemClickHandler( route ) {

        this.props.closeMenu();
        this.props.history.push( route );
    }

    allProductsClickHandler() {

        this.setState({ showSubmenu: !this.state.showSubmenu });
    }

    categoresClickHandler( categoryId ) {
        
        this.props.closeMenu();
        sessionStorage.setItem('perma-category', categoryId);
        this.props.history.push(`/search-products`);
    }

    render() {
        return (
            <div className="mobile-menu-container" onClick={ e => this.menuContainerClickHandler( e ) }>
                <ul className="menu-list">
                    <li className="menu-list__item" onClick={ this.allProductsClickHandler }>
                        <p>Todos los productos</p>
                        <Arrow></Arrow>
                    </li>
                    { this.state.showSubmenu && <li className="menu-list__item submenu">
                        <ul>
                            { this.props.categories.map( ( cat, i ) =>
                                <li key={ i } onClick={ () => this.categoresClickHandler( cat.id ) }>{ cat.name }</li> ) }
                        </ul>
                    </li> }
                    <li className="menu-list__item" onClick={ () => this.menuItemClickHandler( '/' ) }>
                        <p>Inicio</p>
                    </li>
                    <li className="menu-list__item" onClick={ () => this.menuItemClickHandler( '/promos' ) }>
                        <p>Promos</p>
                    </li>
                    <li className="menu-list__item" onClick={ () => this.menuItemClickHandler( '/who-we-are' ) }>
                        <p>Quienes somos</p>
                    </li>
                    <li className="menu-list__item" onClick={ () => this.menuItemClickHandler( '/services' ) }>
                        <p>Servicios</p>
                    </li>
                    <li className="menu-list__item" onClick={ () => this.menuItemClickHandler( '/contact' ) }>
                        <p>Contacto</p>
                    </li>
                </ul>
            </div>
        );
    }
    
}

const MobileMenu = withRouter( MM );
export default MobileMenu;