import React, { Component } from 'react';
import Image1 from '../../../assets/img/1.png';
import Image2 from '../../../assets/img/2.png';
import Image3 from '../../../assets/img/3.png';
import { ReactComponent as LeftArrow } from '../../../assets/icons/izq.svg'; 
import { ReactComponent as RightArrow } from '../../../assets/icons/der.svg'; 

export default class Slideshow extends Component {

    constructor() {
        super();

        this.state = {
            currentImage: 0
        }

        // Bind context to methods
        this.prev = this.prev.bind( this );
        this.next = this.next.bind( this );
        this.updateSlide = this.updateSlide.bind( this );
    }
    
    componentDidMount() {
        
        this.updateSlide();
    }

    componentDidUpdate( prevProps, prevState, snapshot ) {

        if ( prevState.currentImage !== this.state.currentImage ) {

            this.updateSlide();
        }
    }

    prev() {
        
        const current = this.state.currentImage === 0 ? 2 : this.state.currentImage - 1;
        this.setState({ currentImage: current });
    }
    
    next() {
        
        const current = this.state.currentImage === 2 ? 0 : this.state.currentImage + 1;
        this.setState({ currentImage: current });
    }

    updateSlide() {

        const images = document.getElementsByClassName('slideshow__image');
        for (let i = 0, length = images.length; i < length; i++ ) {
            images[ i ].style.display = 'none';
        }

        if ( images.length ) {

            images[ this.state.currentImage ].style.display = 'block';
        }
    }

    render() {
        return (
            <div className="slideshow">
                <img className="slideshow__image fade" src={ Image1 } alt="1"/>
                <img className="slideshow__image fade" src={ Image2 } alt="2"/>
                <img className="slideshow__image fade" src={ Image3 } alt="3"/>

                <LeftArrow
                    className="slideshow__left-arrow"
                    onClick={ this.prev }
                    ></LeftArrow>
                <RightArrow
                    className="slideshow__right-arrow"
                    onClick={ this.next }></RightArrow>
            </div>
        );
    }

}