import React, { Component } from 'react';
import axios from 'axios';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { ReactComponent as FbIcon } from '../../assets/icons/face.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as GlobalIcon } from '../../assets/icons/global.svg';
import { ReactComponent as Logo } from '../../assets/img/logo.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as DownArrowIcon } from '../../assets/icons/arrow.svg';
import { ReactComponent as IconSearch } from '../../assets/icons/icon-search.svg';
import MobileMenu from '../layouts/MobileMenu';

class H extends Component {

    constructor( props ) {
        super();

        this.state = {
            allCategoriesSelectIsOpen: false,
            categories: [],
            showMobileMenu: false
        }

        // Bind events to all methods
        this.detectOutsideSelectClick = this.detectOutsideSelectClick.bind( this );
        this.categoriesSelectClick = this.categoriesSelectClick.bind( this );
        this.toggleAllCategoriesSelectState = this.toggleAllCategoriesSelectState.bind( this );
        this.getCategories = this.getCategories.bind( this );
        this.categoresClickHandler = this.categoresClickHandler.bind( this );
        this.searchKeyupHandler = this.searchKeyupHandler.bind( this );

        this.getCategories();
    }

    componentDidMount() {

        this.detectOutsideSelectClick();
        const mobileMenu = document.getElementById('mobile-menu');
        const mobileMenuInitialPosition = mobileMenu.offsetTop;

        window.addEventListener('scroll', e => {
            if (mobileMenuInitialPosition <= window.scrollY) {
                mobileMenu.style.position = 'fixed';
                mobileMenu.style.top = 0;
                mobileMenu.style['z-index'] = 998;
            } else {
                mobileMenu.style.position = 'initial';
                // mobileMenu.style.top = 0;
            }
        });
    }

    searchKeyupHandler( event ) {
        if ( event.keyCode === 13 ) {
            console.log(event.target.value);
            window.location = '/search-products';
            localStorage.setItem('productName', event.target.value);
        }
    }

    getCategories() {
            
        axios.get(`https://permatrol-backend.herokuapp.com/categories`)
            .then( response => {
                this.setState({ categories: [ ...response.data.elements ] });
            })
    }

    detectOutsideSelectClick() {

        window.addEventListener('click', e => {
            if ( !document.querySelector('.categories-select').contains(e.target) ) {
                this.setState({ allCategoriesSelectIsOpen: false });
            }
        });
    }

    categoriesSelectClick( e ) {
        this.toggleAllCategoriesSelectState();
    }

    toggleAllCategoriesSelectState() {

        this.setState({ allCategoriesSelectIsOpen: !this.state.allCategoriesSelectIsOpen });
    }
    
    // Handlers

    categoresClickHandler( categoryId ) {
        
        sessionStorage.setItem('perma-category', categoryId);
        this.props.history.push(`/search-products`);
    }

    render() {
        return (
            <header className="header">
                { this.state.showMobileMenu
                    && <MobileMenu
                            categories={ this.state.categories }
                            closeMenu={ () => this.setState({ showMobileMenu: false }) }></MobileMenu> }
                <div className="header__information-bar">
                    <FbIcon className="header__icon-fb"></FbIcon>
                    <PhoneIcon className="header__icon-phone"></PhoneIcon>
                    <p className="header__text-cellphone">CEL. 37 47 44 38 45</p>
                    {/* <p className="header__text-phone">TEL. 45 67 89 70</p> */}
                    <GlobalIcon className="header__icon-global"></GlobalIcon>
                    <p className="header__text-dir">Adolfo López Mateos No. 90, Santa Cruz del Astillero, Mpio. El Arenal, Jal. C.P. 45360</p>
                </div>
                <div className="header__logo-bar">
                    <Link to="/" className="logo-container">
                        <Logo className="header__logo-bar-logo"></Logo>
                    </Link>
                    <input className="input" placeholder="Buscar productos" onKeyUp={this.searchKeyupHandler}></input>
                    <Link to="/search-products" className="search-icon-container">
                        <IconSearch />
                    </Link>
                </div>
                <div className="header__responsive-menu-bar" id="mobile-menu">
                    <button className="menu-bar-btn">
                        <MenuIcon onClick={ () => this.setState({ showMobileMenu: true }) }></MenuIcon>
                    </button>
                    <input type="text" className="input" placeholder="Buscar productos"/>
                    <Link to="/search-products" className="search-icon-container">
                        <IconSearch />
                    </Link>
                </div>
                <ul className="header__menu-bar">
                    <li>
                        <button className="categories-select" onClick={ this.categoriesSelectClick }>
                            <span className="categories-select__text">Todas las categorias</span>
                            <MenuIcon className="categories-select__menu-icon"></MenuIcon>
                            { !this.state.allCategoriesSelectIsOpen ||
                                <ul className="categories-select__options">
                                    { this.state.categories.map( ( cat, i ) =>
                                        <li key={ i } onClick={ () => this.categoresClickHandler( cat.id ) }>{ cat.name }</li> ) }
                                </ul>
                            } 
                        </button>
                    </li>
                    <li>
                        <NavLink exact to="/" className="menu-option">
                            <span className="menu-option__text">Inicio</span>
                            <DownArrowIcon className="menu-option__down-arrow"></DownArrowIcon>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/promos" className="menu-option">
                            <span className="menu-option__text">Promos</span>
                            <DownArrowIcon className="menu-option__down-arrow"></DownArrowIcon>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/who-we-are" className="menu-option">
                            <span className="menu-option__text">Quienes somos</span>
                            <DownArrowIcon className="menu-option__down-arrow"></DownArrowIcon>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/services" className="menu-option">
                            <span className="menu-option__text">Servicios</span>
                            <DownArrowIcon className="menu-option__down-arrow"></DownArrowIcon>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" className="menu-option">
                            <span className="menu-option__text">Contacto</span>
                            <DownArrowIcon className="menu-option__down-arrow"></DownArrowIcon>
                        </NavLink>
                    </li>
                </ul>
            </header>
        );
    }
}

const Header = withRouter( H );
export default Header;