import React, { Component } from 'react';
import Image from '../../../assets/img/1.png';

export default class Promo extends Component {

    render() {
        return (
            <div className="promos__promo">
                <img src={ Image } alt="1"/>
                <h6>Promoción</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quam neque, fermentum quis ullamcorper eget, accumsan congue neque. Phasellus arcu tortor, malesuada feugiat tincidunt eu, ornare vitae sem. Donec convallis nisl lacinia turpis dignissim, id tristique turpis tincidunt. Nullam gravida lorem diam, lacinia venenatis felis semper quis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec non enim imperdiet, mattis elit at, pretium mauris nullam.</p>
            </div>
        );
    }

}