import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ProductCard extends Component {

    constructor( props ) {
        super();
    }

    render() {
        const { product } = this.props;

        return (
            <div className="product-card">
                <div className="product-card__container--img">
                    <img src={ 'https://permatrol-backend.herokuapp.com/img/' + product.image } alt="Imagen del producto"></img>
                </div>
                <div className="product-card__container--info">
                    <Link to={ `/product-detail/${ product.id }` }>{ product ? product.name : 'Permagel 1/2 l' }</Link>
                    {/* <p>$60.00</p> */}
                </div>
            </div>
        );
    }

}