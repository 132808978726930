import React, { Component } from 'react';
import ProductsGrid from '../../reusable/ProductsGrid';
import { ReactComponent as OrderTypeIcon } from '../../../assets/icons/order-type.svg';

export default class FilteredProducts extends Component {

    constructor( props ) {
        super();

        this.state = {
            orderBy: 'name',
            orderType: 'asc',
            totalPages: 0
        }
    }

    componentDidUpdate( prevProps ) {
        
        if ( prevProps.pagination.total_items !== this.props.pagination.total_items ) {

            this.setState({ totalPages: Math.ceil( this.props.pagination.total_items / 10 ) });
        }
    }

    // Handlers

    pageItemClickHandler( page ) {

        this.props.getProducts( page );
    }

    render() {
        const { pagination, currentPage, products, orderByChangeHandler, productsLoading, selectedCategoryName } = this.props;
        const { total_items, items_per_page, page, total_pages } = pagination;

        return (
            <div className="filtered-products">
                <p className="filtered-products__title">{ selectedCategoryName || '' }</p>
                <div className="filtered-products__filter-bar">
                    <p className="results-text">
                        Artículos { ( ( page - 1) * items_per_page ) + 1 } - { page == total_pages ? total_items : page * items_per_page } de { this.props.pagination.total_items }
                    </p>
                    <div className="filter-container">
                        <label htmlFor="orderBy">Ordenar por</label>
                        <select value={ this.state.orderBy } name="orderBy"
                            id="orderBy" className="input" onChange={ ( e ) => this.setState({ orderBy: e.target.value }, () => orderByChangeHandler( this.state.orderBy, this.state.orderType ) )}>
                            <option value="name">Nombre</option>
                            <option value="category_name">Categoría</option>
                            <option value="brand_name">Marca</option>
                            <option value="characteristics">Presentación</option>
                        </select>
                        <p className={ this.state.orderType === 'asc' ? 'order-type--asc' : 'order-type--desc' } onClick={ () => {
                            this.setState(
                                { orderType: this.state.orderType === 'asc' ? 'desc' : 'asc' },
                                () => orderByChangeHandler( this.state.orderBy, this.state.orderType ));
                            } }>
                                <OrderTypeIcon className="order-type--asc"></OrderTypeIcon>
                            </p>
                    </div>
                </div>
                { products.length === 0 && !productsLoading && <p className="no-products-text">No se han encontrado coincidencias</p> }
                <ProductsGrid products={ products }></ProductsGrid>
                <div className="filtered-products__filter-bar">
                    <p className="results-text">
                        Artículos { ( ( page - 1) * items_per_page ) + 1 } - { page == total_pages ? total_items : page * items_per_page } de { this.props.pagination.total_items }
                    </p>
                    <ul className="pagination">
                        { currentPage !== 1
                            && <li onClick={ this.pageItemClickHandler.bind( this, currentPage - 1 ) }>{ '<' }</li> }
                        { 
                            Array( this.state.totalPages )
                                .fill(1)
                                .map( ( item, i ) =>
                                        {   
                                            const position = i + 1;
                                            return (
                                                <li
                                                    key={ i }
                                                    className={ currentPage === position ? 'item-selected' : '' }
                                                    onClick={ this.pageItemClickHandler.bind( this, position ) }>{ position }</li>);
                                        }
                                )
                         }
                        { currentPage !== total_pages
                            && <li onClick={ this.pageItemClickHandler.bind( this, currentPage + 1 ) }>></li> }
                    </ul>
                </div>
            </div>
        );
    }

}