import React, { Component } from 'react';
import ProductCard from './ProductCard';

export default class ProductsGrid extends Component {

    constructor( props ) {
        super();
    }

    render() {
        return (
            <div className="products-grid">
                { this.props.products
                    .map( ( prod, i ) => ( <ProductCard className="products-grid__card" key={ i } product={ prod }></ProductCard> ) ) }
            </div>
        );
    }

}