import React, { Component } from 'react';
import Breadcrumb from '../../reusable/Breadcrumb';
import ServicesJSON from './services-array.json';
import ServicesImage from '../../../assets/img/services.png';

export default class Services extends Component {
    
    constructor() {
        super();

        this.breadcrumbArray = [
            { title: 'Inicio', path:"/" }, 
            { title: 'Servicios', path:"/services" }
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb segments={ this.breadcrumbArray }></Breadcrumb>
                <section className="services-container">
                    <img
                        src={ ServicesImage }
                        alt="Servicios"
                        className="services-container__image"/>
                    { 
                        ServicesJSON.map(( service, i ) => (
                            <article className="services-container__item" key={ i }>
                                <h6>{ service.name }</h6>
                                <p>{ service.description }</p>
                            </article>
                        ))
                    }
                </section>
            </div>
        );
    } 

} 