import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

class FYPB extends Component {

    constructor() {
        super();

        this.categoryId = null;
        this.brandId = null;
        this.characteristic = null;

        this.state = {
            categories: [],
            brands: [],
            characteristics: [],
        }

        this.getCategories = this.getCategories.bind( this );
        this.getBrands = this.getBrands.bind( this );
        this.getCharacteristics = this.getCharacteristics.bind( this );
        this.searchClickHandler = this.searchClickHandler.bind( this );

        this.getCategories();
        this.getBrands();
        this.getCharacteristics();
    }

    getCategories() {
            
        axios.get(`https://permatrol-backend.herokuapp.com/categories`)
            .then( response => this.setState({ categories: [ ...response.data.elements ] }));
    }

    getBrands() {
            
        axios.get(`https://permatrol-backend.herokuapp.com/brands`)
            .then( response => this.setState({ brands: [ ...response.data.elements ] }));
    }

    getCharacteristics() {

        axios.get(`https://permatrol-backend.herokuapp.com/characteristics`)
            .then( response => this.setState({ characteristics: [ ...response.data.elements ] }));
    }

    // Handlers

    searchClickHandler() {

        // if ( !this.categoryId || !this.brandId || !this.characteristic ) { return; }
        if ( this.categoryId ) { sessionStorage.setItem('perma-category', this.categoryId); }
        if ( this.brandId ) { sessionStorage.setItem('perma-brand', this.brandId); }
        if ( this.characteristic ) { sessionStorage.setItem('perma-characteristic', this.characteristic); }

        this.props.history.push(`/search-products`);
    }

    render() {
        return (
            <section className="find-your-product">
                <h3 className="find-your-product__title">Encuentra tu producto</h3>
                <p className="find-your-product__secondary-text">Tenemos lo que necesitas. </p>
                <form className="find-your-product__form">
                    <select className="select" onChange={ ( e ) => this.categoryId = e.target.value }>
                        <option value="">Seleccionar categoría</option>
                        { this.state.categories.map( ( cat, i ) =>
                            <option key={ i } value={ cat.id }>{ cat.name }</option> ) }
                    </select>
                    <select className="select" onChange={ ( e ) => this.brandId = e.target.value }>
                        <option value="">Seleccionar marca</option>
                        { this.state.brands.map( ( brand, i ) =>
                            <option key={ i } value={ brand.id }>{ brand.name }</option> ) }
                    </select>
                    <select className="select" onChange={ ( e ) => this.characteristic = e.target.value }>
                        <option value="">Seleccionar presentación</option>
                        { this.state.characteristics.map( ( char, i ) =>
                            <option key={ i } value={ char.name }>{ char.name }</option> ) }
                    </select>
                    <a className="find-your-product__search-btn" onClick={ () => this.searchClickHandler() }>Buscar</a>
                </form>
            </section>
        );
    }

}

const FindYourProductBanner = withRouter( FYPB );
export default FindYourProductBanner;