import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './assets/sass/main.scss';

// LAYOUTS
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';

// PAGES
import Welcome from './components/pages/welcome/Welcome';
import Promos from './components/pages/promos/Promos';
import SearchProducts from './components/pages/search-products/SearchProducts';
import WhoWeAre from './components/pages/who-we-are/WhoWeAre';
import Services from './components/pages/services/Services';
import Contact from './components/pages/contact/Contact';
import ProductDetail from './components/pages/product-detail/ProductDetail';

function App() {

  return (
    <div className="App">
      <Router>
        <Header></Header>

        { /* Routes */ }
        <Route path="/" exact component={ Welcome } />
        <Route path="/promos" exact component={ Promos } />
        <Route path="/search-products" exact component={ SearchProducts } />
        <Route path="/who-we-are" exact component={ WhoWeAre } />
        <Route path="/services" exact component={ Services } />
        <Route path="/contact" exact component={ Contact } />
        <Route path="/product-detail/:id" exact component={ ProductDetail } />

        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
