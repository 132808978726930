import React, { Component } from 'react';
import Breadcrumb from '../../reusable/Breadcrumb';
import WhoWeAreImage from '../../../assets/img/who-we-are.png';

export default class WhoWeAre extends Component {

    constructor() {
        super();

        this.breadcrumbArray = [
            { title: 'Inicio', path:"/" }, 
            { title: 'Quienes somos', path:"/who-we-are" }
        ];
    }

    render() {
        return (
            <div>
                <Breadcrumb segments={ this.breadcrumbArray }></Breadcrumb>
                <div className="who-we-are">
                    <img
                        src={ WhoWeAreImage }
                        alt="¿Quienes somos?"
                        className="who-we-are__image"/>
                    <article className="who-we-are__our-history">
                        <h6>PERMALUB SA DE CV</h6>
                        <p>Comenzó como una pequeña empresa <strong>MEXICANA</strong> en 1998, con el objetivo de dar una alternativa  para el parque automotriz nacional, en la fabricación de aceites lubricantes, grasas y anticongelantes.
                            <br/>
                            <br/>
                            <strong>Fabricamos</strong> productos para <strong>automóviles, motocicletas, camiones de trabajo pesado y vehículos agrícolas</strong>. Nuestra experiencia acumulada, por más de 20 años, junto a la presencia constante con nuestros clientes del occidente de México, nos hemos convertido en una referencia en <strong>lubricantes</strong>.
                            <br/>
                            <br/>
                            Nuestros productos cumplen con las <strong>especificaciones</strong> que recomiendan los fabricantes de automóviles y maquinarias. Nos mantenemos a la <strong>vanguardia</strong> para desarrollar productos que se adapten a las necesidades del mercado.
                        </p>
                    </article>
                    <article className="who-we-are__mision">
                        <h6>Misión</h6>
                        <p>Distribuir aceites lubricantes, grasas y anticongelantes de primera calidad en las principales plazas del país, que nuestros productos cumplan y excedan las expectativas de sus clientes. Mantener y mejorar la calidad de nuestros productos y servicios, de igual forma, contribuir al mejoramiento y preservación del medio ambiente.</p>
                    </article>
                    <article className="who-we-are__vision">
                        <h6>Visión</h6>
                        <p>Ser una empresa de calidad clases mundial en el mercado de los aceites y lubricantes, satisfacer a todos nuestros clientes internos y externos.
                            <br/>
                            <br/>
                            Los valores forman parte del servicio que ofrecemos a través de quienes integran la empresa en sus distintos ámbitos de trabajo.</p>
                    </article>
                </div>
            </div>
        );
    }

}