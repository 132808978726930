import React, { Component } from 'react';
import ProductsGrid from '../../reusable/ProductsGrid';
import axios from 'axios';

export default class FeaturedProducts extends Component {

    constructor( props ) {
        super();

        this.state = {
            products: []
        };

        this.getProducts = this.getProducts.bind( this );

        this.getProducts();
    }

    getProducts() {

        axios.get('https://permatrol-backend.herokuapp.com/featured-products')
            .then( response => this.setState({ products: [ ...response.data.elements ] }) );
    }

    render() {
        return (
            <section className="featured-products">
                <p className="featured-products__secondary-text">Productos</p>
                <h2 className="featured-products__title">Destacados</h2>
                <ProductsGrid products={ this.state.products }></ProductsGrid>
            </section>
        );
    }

}