import React, { Component } from 'react';
import Filters from './Filters';
import FilteredProducts from './FilteredProducts';
import Breadcrumb from '../../reusable/Breadcrumb';
import axios from 'axios';

export default class SearchProducts extends Component {

    constructor( props ) {
        super();
        
        this.breadcrumbArray = [
            { title: 'Inicio', path:"/" }, 
            { title: 'Aceites', path:"/search-products" }
        ];

        this.state = {
            value: { min: 2, max: 10 },
            products: [],
            productsPagination: {
                total_items: 0
            },
            characteristics: [],
            brands: [],
            currentPage: 1,
            selectedCategory: null,
            selectedCategoryName: null,
            selectedBrand: null,
            selectedCharacteristic: null,
            order_by: 'name',
            order_type: 'asc',
            productsLoading: false
        };

        this.getProducts = this.getProducts.bind( this );
        this.getCharacteristics = this.getCharacteristics.bind( this );
        this.getBrands = this.getBrands.bind( this );
        this.initFilters = this.initFilters.bind( this );
    }

    componentDidMount() {

        this.initFilters();
        this.props.history.listen( location => this.initFilters() );
    }

    initFilters() {

        this.setState({
            selectedCategory: Number( sessionStorage.getItem('perma-category') ),
            selectedBrand: Number( sessionStorage.getItem('perma-brand') ),
            selectedCharacteristic: sessionStorage.getItem('perma-characteristic')
        }, () => {
        
            this.getProducts();
            this.getCharacteristics();
            this.getBrands();
            
            sessionStorage.removeItem('perma-category');
            sessionStorage.removeItem('perma-brand');
            sessionStorage.removeItem('perma-characteristic');
        } );
    }

    getProducts() {
        this.setState({ productsLoading: true });
        const { currentPage, selectedCategory, selectedBrand, selectedCharacteristic, order_by, order_type } = this.state;
        let url = `https://permatrol-backend.herokuapp.com/products?page=${ currentPage }&order_by=${ order_by }&order_type=${ order_type }`;
        
        if ( selectedCategory ) {

            url += `&category_id=${ selectedCategory }`;
        }

        if ( selectedBrand ) {

            url += `&brand_id=${ selectedBrand }`;
        }

        if ( selectedCharacteristic ) {

            url += `&characteristic=${ selectedCharacteristic }`;
        }
        
        if ( localStorage.getItem('productName') ) {

            url += `&name=${ localStorage.getItem('productName') }`;
            localStorage.removeItem('productName');
        }

        axios.get( url )
            .then( response => this.setState({
                    products: [ ...response.data.elements ],
                    productsPagination: { ...response.data.pagination },
                    productsLoading: false
                }));
    }

    getCharacteristics() {

        axios.get(`https://permatrol-backend.herokuapp.com/characteristics`)
            .then( response => {
                this.setState({ characteristics: [ ...response.data.elements ] });
            })
    }
        
    getBrands() {
            
        axios.get(`https://permatrol-backend.herokuapp.com/brands`)
            .then( response => {
                this.setState({ brands: [ ...response.data.elements ] });
            })
    }

    render() {
        return (
            <div>
                <Breadcrumb segments={ this.breadcrumbArray }></Breadcrumb>
                <section className="search-products">
                    <Filters
                        characteristics={ this.state.characteristics }
                        brands={ this.state.brands }
                        selectedCategory={ this.state.selectedCategory }
                        selectedBrand={ this.state.selectedBrand }
                        selectedCharacteristic={ this.state.selectedCharacteristic }
                        categoryChangeHandler={ ( category = {} ) => this.setState({ selectedCategory: category.id, selectedCategoryName: category.name }, () => this.getProducts() ) }
                        brandChangeHandler={ ( brandId = null ) => this.setState({ selectedBrand: brandId }, () => this.getProducts() ) }
                        characteristicChangeHandler={ ( characteristic = null ) => this.setState({ selectedCharacteristic: characteristic }, () => this.getProducts() ) }></Filters>
                    <FilteredProducts
                        selectedCategoryName={ this.state.selectedCategoryName }
                        productsLoading={ this.state.productsLoading }
                        products={ this.state.products }
                        pagination={ this.state.productsPagination }
                        getProducts={ ( page = 1 ) => this.setState({ currentPage: page }, () => this.getProducts() ) }
                        currentPage={ this.state.currentPage }
                        orderByChangeHandler={ ( order_by, order_type ) => this.setState({ order_by, order_type }, () => this.getProducts() ) }></FilteredProducts>
                </section>
            </div>
        );
    }

}